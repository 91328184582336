define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/shop/components/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, CommerceAuth, NotAcl) => AclRouter.extend({

  appRoutes: {
    'files/internal': 'internalList',
    'files/shared': 'sharableList',
    'files/delete/:id(/:goto)': 'remove',
    'files/details/:id(/:goto)': 'details',
    'file-categories': 'fileCategories',

    'files/upload/internal': 'uploadInternal',
    'files/upload/internal(/:object_instance_id)': 'uploadInternal',
    'files/upload/shared': 'uploadShared',
    'files/upload/shared(/:object_instance_id)': 'uploadShared',
  },

  acls: [
    new LoggedIn(),
    new CommerceAuthAcl({ alias: CommerceAuthAcl.fileAlias }),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderRequestManagerAlias })),
  ],

  overwriteAcls: [{
    routes: [
      'files/upload/internal(/:object_instance_id)', 'files/upload/shared(/:object_instance_id)',
    ],
    acls: [
      new LoggedIn(),
    ],
  }],

  getController(callback) {
    return require(['modules/shop/controllers/files'], callback);
  },
}));
