define([
  'application',
  'jquery',
  'backbone',
  'modules/common/components/component',
  'modules/upx/components/upx',
  'modules/common/components/locale',
  'modules/shop.common/collections/deviceConfig',
], (App, $, Backbone, Component, Upx, Locale, DeviceConfigCollection) => {
  const Menu = Component.extend({

    ALIAS_ButtonBlankShopProduct: 'ShopModule::DashboardButtonBlankShopProduct',
    ALIAS_ButtonShopProduct: 'ShopModule::DashboardButtonShopProduct',
    ALIAS_ButtonGiftCard: 'ShopModule::DashboardButtonGiftCard',
    ALIAS_ButtonMemberCard: 'ShopModule::DashboardButtonMemberCard',
    ALIAS_ButtonCategory: 'ShopModule::DashboardButtonCategory',
    ALIAS_ButtonTopUp: 'ShopModule::DashboardButtonTopUp',
    ALIAS_ButtonDeductLoyaltyPoints: 'ShopModule::DashboardButtonDeductLoyaltyPoints',
    ALIAS_ButtonBoughtUsedGoods: 'ShopModule::DashboardButtonBoughtUsedGoods',
    ALIAS_ButtonNegativeShopProduct: 'ShopModule::DashboardButtonNegativeShopProductType',
    ALIAS_ButtonEmpty: 'ShopModule::DashboardButtonEmpty',
    ALIAS_Other: 'ShopModule::Other',
    ALIAS_PaymentChoice: 'ShopModule::PaymentChoice',
    ALIAS_Shop: 'ShopModule::Shop',

    PATH_CheckoutButtons: 'checkout.button',
    PATH_Shop: 'shop',
    PATH_PaymentMethods: 'payment_methods',
    PATH_Receipt: 'receipt',
    PATH_Printer: 'printer',
    PATH_PriceTagPrinter: 'price_tag_printer',
    PATH_StickerPrinter: 'price_sticker_printer',
    PATH_PinTerminal: 'pin_terminal',
    PATH_OnScreenKeyboard: 'on_screen_keyboard',
    PATH_WifiSetting: 'wifi_setting',
    PATH_Location: 'location',
    PATH_ProductCatalogAttributes: 'product_catalog_attributes',
    PATH_DeviceMode: 'device_mode',
    PATH_KeyboardLayout: 'keyboard_layout',

    MODE_Retail: 'retail',
    MODE_Hospitality: 'restaurant', // 'restaurant' for backwards compatibility

    initialize(options) {
      this.device = new Backbone.Model();
      this.collection = new DeviceConfigCollection();
      this.def = null;
      this.defaultDeviceUuid = null;
      this.type_ids = [];
      this.available_device_modes = [
        this.MODE_Retail,
        this.MODE_Hospitality,
      ];
    },

    setDefaultDeviceUuid(uuid) {
      this.defaultDeviceUuid = uuid;
    },

    unload() {
      this.deviceUuid = null;
      this.def.reject(); // reject the old object in case something was listening to it
      this.collection.reset();
    },

    load(resolve_when_not_found) {
      resolve_when_not_found = resolve_when_not_found || true;

      if (this.def !== null) {
        // there is something already loaded
        this.unload();
      }

      const def = this.def = $.Deferred();
      const deviceUuid = this.deviceUuid = this.getDeviceUUID();
      if (deviceUuid === false) {
        console.warn('No deviceUuid is set');
        if (resolve_when_not_found) {
          return this.def.resolve();
        }
        return this.def.reject();
      }
      const self = this;

      Upx.call('ShopModule', 'getDeviceWithConfigByUuid',
        {
          uuid: deviceUuid,
        }).then(
        (response) => {
          if (response.device_configs) {
            response.device_configs.forEach((data) => {
              // if this check is not here, backbone saves only first model with undefined key
              if (!!data.alias && data.alias !== 'undefined') {
                self.collection.add(data);
              }
            });
          }
          delete response.device_configs;
          self.device.set(response);
          def.resolve();
        },
        () => {
          if (resolve_when_not_found) {
            def.resolve();
          } else {
            def.reject();
          }
        },
      );
      return def.promise();
    },

    collectionFilteredWithPrefix(prefix) {
      const filtered = this.collection.filter((box) => box.get('alias').startsWith(prefix));
      return new DeviceConfigCollection(filtered);
    },

    getConfig(path) {
      return this.collection.get(path);
    },

    setConfigCall(device_configs, def) {
      const self = this;
      Upx.call('ShopModule', 'setDeviceWithConfigByUuid',
        {
          fields: {
            uuid: this.deviceUuid,
            device_configs,
          },
        }).then(
        () => {
          self.collection.add(device_configs, { merge: true });
          def.resolve();
        },
        def.reject,
      );
    },

    getTypeAliasId(type_alias) {
      const def = $.Deferred();
      const self = this;
      if (type_alias in this.type_ids) {
        def.resolve(this.type_ids[type_alias]);
      } else {
        Upx.call('ShopModule', 'listDeviceConfigTypes',
          {
            start: 0,
            limit: 1,
            filters: [
              {
                name: 'module_name__=',
                val: type_alias.split('::')[0],
              },
              {
                name: 'alias__=',
                val: type_alias.split('::')[1],
              },
            ],
          }).then((response) => {
          if (response.data && response.data[0]) {
            self.type_ids[type_alias] = response.data[0].id;
            def.resolve(self.type_ids[type_alias]);
          } else {
            console.log(`No type alias found for ${type_alias}`);
            def.reject();
          }
        });
      }
      return def.promise();
    },

    setConfig(path, extra, type_alias) {
      if (!type_alias) {
        type_alias = this.ALIAS_Other;
      }

      const def = $.Deferred();
      const self = this;
      if (this.deviceUuid) {
        this.getTypeAliasId(type_alias).then(
          (type_id) => {
            self.setConfigCall([{
              alias: path,
              device_config_type_id: type_id,
              extra,
            }], def);
          },
          def.reject,
        );
      } else {
        console.log('No device UUID set');
        def.reject();
      }

      return def.promise();
    },

    getDeviceUUID() {
      const variable = 'UUID';
      const query = window.location.search.substring(1);
      const vars = query.split('&');
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]).toUpperCase() == variable) {
          return decodeURIComponent(pair[1]);
        }
      }
      if (this.defaultDeviceUuid) {
        console.warn(`No uuid set for this device. Using default uuid from configuration:  '${this.defaultDeviceUuid}'`);
        return this.defaultDeviceUuid;
      }
      return false;
    },

    getModeLabel(mode) {
      switch (mode) {
        case this.MODE_Retail:
          return Locale.translate('retail');
        case this.MODE_Hospitality:
          return Locale.translate('hospitality');
      }
      return mode;
    },

    getDefaultDeviceMode() {
      return this.MODE_Retail;
    },

    listDeviceModes() {
      const collection = new Backbone.Collection();

      this.available_device_modes.forEach((mode) => {
        collection.add({
          label: this.getModeLabel(mode),
          id: mode,
          is_default: mode === this.getDefaultDeviceMode(),
        });
      });

      return collection;
    },

    getDeviceMode() {
      const model = this.getConfig(this.PATH_DeviceMode);
      // If there is no mode set. OR there is none set in the model.
      // We return the default mode.
      if (!model || !model.has('extra.mode')) {
        return this.getDefaultDeviceMode();
      }

      return model.get('extra.mode');
    },

    setDeviceMode(mode) {
      if (this.available_device_modes.indexOf(mode) === -1) {
        throw Error(`Mode "${mode}" is not a available mode`);
      }

      return this.setConfig(this.PATH_DeviceMode, {
        mode,
      });
    },

    getDeviceName() {
      return this.device.get('name');
    },

    getDeviceId() {
      return this.device.get('id');
    },
  });

  return new Menu();
});
