define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, NotAcl) => AclRouter.extend({

  appRoutes: {
    'service-request': 'list',
    'service-request/create': 'create',
    'service-request/details/:id(/:swapto)': 'details',

    'service-request-type': 'types',
    'service-request-type/create': 'typeCreate',
    'service-request-type/edit/:id': 'typeEdit',
    'service-request-type/delete/:id': 'typeDelete',
  },

  acls: [
    new LoggedIn(),
    new CommerceAuthAcl({ alias: CommerceAuthAcl.relationAlias }),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderRequestManagerAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/serviceRequests'], callback);
  },
}));
