define([
], (
) => ({

  register() {
    if (this.isInWebMode() && this.isAndroidTablet()) {
      // register fixes for android tablet for the keyboard
      this.forceFullScreenOnClick();
      this.adjustTopMarginOnFocusIn();
      this.setFixedViewPortHeight();
      this.resetTopMarginOnFocusOut();
    }
  },

  isAndroidTablet() {
    return !!navigator.userAgent.match(/(Android)/);
  },

  /**
         * prepend static height to the viewport taken from the original height on load
         * this way the height won't be scaled when the keyboard shows up
         */
  setFixedViewPortHeight() {
    const currentViewport = document.querySelector('meta[name=viewport]').getAttribute('content');
    if (currentViewport.includes('height') === false) {
      document.querySelector('meta[name=viewport]').setAttribute('content',
        `height=${screen.height}px, ${currentViewport}`);
    }
  },

  /**
         * the keyboard can go over the input, making it impossible to see what you type using they keyboard
         * for this reason we add a negative top margin to the page to move the input above the keyboard
         */
  adjustTopMarginOnFocusIn() {
    document.addEventListener('focusin', (event) => {
      const ignoredTypes = [
        'submit',
        'reset',
        'radio',
        'checkbox',
        'button',
        'file',
      ];
      const $targetEl = $(event.target);
      if (
        // Check if the target input is an 'input' or an 'textarea'
        ($targetEl.is('input') || $targetEl.is('textarea'))
                    // Ignore non text field inputs
                    && !(ignoredTypes.includes($targetEl.attr('type')))
                    // Check if the target is not readonly.
                    && !$targetEl.is('[readonly]')
                    // Check if the target is not disabled.
                    && !$targetEl.is('[disabled]')
      ) {
        const elementHeight = $targetEl.offset().top;
        const pixels_from_top = elementHeight - 100;// -100 so we still have some space left at the top
        $('#main').css('margin-top', `-${pixels_from_top}px`);
      }
    });
  },

  /**
         * resets the top margin to 0px
         */
  resetTopMarginOnFocusOut() {
    document.addEventListener('focusout', () => {
      $('#main').css('margin-top', '0px');
    });
  },

  /**
         * When you click anywhere in the page it will switch to full screen mode
         *
         * Note: it is only possible to call webkitRequestFullScreen when it is not a virtual (isTrusted) click event
         */
  forceFullScreenOnClick() {
    window.onclick = function () {
      document.documentElement.webkitRequestFullScreen();
    };
  },

  /**
         * Check if the application is in web mode
         *
         * @returns {boolean}
         */
  isInWebMode() {
    const variable = 'webmode';
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]) === '1' || decodeURIComponent(pair[1]) === 'true';
      }
    }

    return false;
  },
}));
