define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, NotAcl) => AclRouter.extend({

  appRoutes: {
    stocks: 'list',
    'stock-mutations': 'listMutations',
    'stock-mutations/supplier-order/:id': 'listMutationsForSupplierOrder',
    'stock-mutations/product/:id': 'listMutationsForProduct',
    'stock/assembly-recipes': 'assembly',
    'stock/assembly-recipes/:swapTo': 'assembly',
    'stock/assembly-recipes/:swapTo/:id': 'assembly',
  },

  acls: [
    new LoggedIn(),
    new CommerceAuthAcl({ alias: CommerceAuthAcl.orderAlias }),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderRequestManagerAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/stocks'], callback);
  },
}));
