define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn) => AclRouter.extend({

  appRoutes: {
    'error/forbidden': 'forbidden',
  },

  acls: [
    new LoggedIn(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/errors'], callback);
  },
}));
