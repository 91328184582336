define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
], (AclRouter, LoggedIn) => AclRouter.extend({

  appRoutes: {
    profile: 'profile',
    'change-password': 'password',
  },

  acls: [
    new LoggedIn(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/profile'], callback);
  },
}));
