define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, NotAcl) => AclRouter.extend({

  appRoutes: {
    products: 'list',
    'products/fallback-valuation/:productGroupId': 'list',
    'products/details/:id(/:swapTo)': 'product',
    'shop-products/details/:id(/:swapTo)': 'shopProduct',
    'product-categories': 'categories',
    'product-categories/create': 'categoriesCreate',
    labels: 'labels',
    'product-image-categories': 'productImageCategories',

    'products/duplicate/:product_id': 'duplicateProduct',

    'products/create': 'createChoose',
    'products/create(/:type/:attribute_set_id)': 'create',
    'products/create-from-bought-good/:id(/:attribute_set_id)': 'createFromBoughtUsedGood',

    // Legacy route, used by external sources
    'shop-product/redirect/:id(/:swapTo)': 'shopProduct',
  },

  acls: [
    new LoggedIn(),
    new CommerceAuthAcl({ alias: CommerceAuthAcl.productAlias }),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/products'], callback);
  },
}));
