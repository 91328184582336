define([
  'application',
  'modules/common/components/component',
  'modules/common/components/locale',
], (App, Component, Locale) => {
  const comp = Component.extend({
    FALLBACK_LANG: 'en',

    setLocaleFromProfile(profile) {
      let lang = profile.get('relation_data_profile.language_iso2');
      const allowedLocales = App.settings.admin.locales;

      if (!lang || allowedLocales.indexOf(lang) === -1) {
        lang = navigator.userLanguage || navigator.language;
        if (lang.indexOf('-') > -1) {
          lang = lang.substr(0, 2);
        }
      }

      if (allowedLocales.indexOf(lang) > -1) {
        Locale.setLocale(lang);
      } else {
        Locale.setLocale(this.FALLBACK_LANG);
      }
    },

  });

  return new comp();
});
