define([
  'application',
  'modules/common/components/module',
  'modules/pwa/events/worker/registerFailed',
  'modules/pwa/component/worker',
  'modules/pwa/events/worker/sourcesUpdate',

  'workbox-window',
],
(App, AppModule, RegisterEventFailed, Worker, SourcesUpdateEvent,
  { Workbox, messageSW }) => {
  const module = new AppModule({
    id: 'modules/pwa/application',

    onStart() {
      if (window.live) {
        console.warn('[SW]  Web worker disabled in live mode');
      } else if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
          const wb = new Workbox('/sw.js');
          let registration;

          // update control based on https://developers.google.com/web/tools/workbox/guides/advanced-recipes?hl=en#offer_a_page_reload_for_users
          const triggerUpdateEvent = () => {
            const event = new SourcesUpdateEvent();
            event.trigger();
            event.doUpdate = () => {
              console.log('[SW] updating the app to new version');
              // Assuming the user accepted the update, set up a listener
              // that will reload the page as soon as the previously waiting
              // service worker has taken control.
              wb.addEventListener('controlling', (event) => {
                window.location.reload();
              });
              if (registration && registration.waiting) {
                // Send a message to the waiting service worker,
                messageSW(registration.waiting, { type: 'SKIP_WAITING' });
              }
            };
          };
          wb.addEventListener('waiting', triggerUpdateEvent);
          wb.addEventListener('externalwaiting', triggerUpdateEvent);

          wb.register().then((r) => {
            registration = r;
            Worker.register(registration.active);
            console.info('[SW] Web worker registered');
          }).catch((registrationError) => {
            const event = new RegisterEventFailed({
              error: registrationError,
            });
            event.trigger();
          });
        });
      } else {
        console.warn('[SW]  No web worker support');
      }
    },
  });

  return module.register();
});
