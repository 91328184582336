define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
], (AclRouter, LoggedIn) => AclRouter.extend({

  appRoutes: {
    'shop-product-feed': 'shopProductFeed',
    'shop-product-feed/:swapTo(/:shopProductFeedId)': 'shopProductFeed',
  },

  acls: [
    new LoggedIn(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/shopProductFeed'], callback);
  },
}));
