define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (
  AclRouter, LoggedIn, CommerceAuthAcl, NotAcl,
) => AclRouter.extend({

  appRoutes: {
    apps: 'overview',
    'apps/:app': 'details',
    'apps/:app/:page': 'details',
    'apps/:app/:page/:cacheId': 'details',
  },

  acls: [
    new LoggedIn(),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderRequestManagerAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/apps'], callback);
  },
}));
