define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/collections/Shop',
  'modules/shop/models/upx/Shop',
  'modules/shop/collections/ShopType',
], (
  $, _, Backbone, ShopCollection, ShopModel, ShopTypeCollection,
) => {
  const Collection = ShopCollection.extend({

    offline: false,

    model: ShopModel,
    /**
         * @description Checks if the attribute exists with a similar name, returns false when not loaded.
         * @return {boolean}
         */
    existsByName(name) {
      if (!this.loaded) {
        return false;
      }

      return !!this.findWhere({
        name,
      });
    },

    getDefault() {
      return this.findWhere({ is_default: true });
    },

    getBackoffice() {
      return this.findWhere({ 'shop_type.alias': ShopTypeCollection.ALIAS_BACKOFFICE });
    },

    isOrderPrefixUsed(orderPrefix) {
      orderPrefix = orderPrefix || '';
      return !!this.findWhere({ order_prefix: orderPrefix.toUpperCase() });
    },

    getMainCategoryIds() {
      return this
        .pluck('category_id')
        .filter(
          (n, index, self) => !!n && self.indexOf(n) === index, // non empty + unique
        ) || [];
    },

    listUsableShops() {
      return this.chain()
        .filter((model) => model.get('shop_type.alias') !== ShopTypeCollection.ALIAS_FALLBACK)
        .map((model) => model.toJSON())
        .value();
    },

    listShopIdsWithTypeAlias(alias) {
      return this.chain()
        .filter((model) => model.get('shop_type.alias') === alias)
        .map((model) => model.get('id'))
        .value();
    },

    listShopsForProductFeed() {
      const types = [
        ShopTypeCollection.ALIAS_WEB_WITH_SYNC,
        ShopTypeCollection.ALIAS_SK_AS_POS,
      ];
      return this.chain()
        .filter((model) => types.includes(model.get('shop_type.alias')))
        .value();
    },

    makeAttributeSelectOption(model) {
      return {
        id: model.get('id'),
        text: model.get('name'),
      };
    },

  });

  return new Collection();
});
