define([
  'jquery',
  'underscore',
  'backbone',
], (
  $, _, Backbone,
) => ({

  MODE_KASSA: 'kassa',
  MODE_APP: 'app',

  /**
         * Gets the variable specified in url
         *
         * @returns {string}
         */
  getVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }

    return null;
  },
  /**
         * Checks what mode the application is in
         *
         * @returns {string}
         */
  getMode() {
    return this.getVariable('mode');
  },

}));
