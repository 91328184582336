define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, NotAcl) => AclRouter.extend({

  appRoutes: {
    reports: 'reports',
    'reports/:reportId/view': 'viewReport',
    'reports/create-from-type/:swapTo': 'createReportFromType',
    'reports/quick-report/:cacheId': 'quickReport',

    'payment-statistics': 'paymentStatistics',
    'report/stock-by-attribute-option': 'stockByAttribute',
    'report/stock-by-attribute-option/result/:cacheId': 'stockByAttributeResult',
  },

  acls: [
    new LoggedIn(),
    new CommerceAuthAcl({ alias: CommerceAuthAcl.orderAlias }),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderRequestManagerAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/reports'], callback);
  },

}));
