define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ProductsModule/collections/ProductPriceType',
], (
  $, _, Backbone, UpxCollection,
) => {
  const Collection = UpxCollection.extend({

    priceAliasDiscount: 'Discount',
    priceAliasOther: 'Other',
    priceAliasDefault: 'Default',
    priceAliasBottom: 'Bottom',
    priceAliasCost: 'Cost',
    priceAliasPurchase: 'Purchase',
    priceAliasRecommendedRetail: 'RecommendedRetail',

    offline: false,

    loaded: false,

    load(force) {
      const def = new $.Deferred();

      if (!this.loaded || force) {
        this.fetch(this._getParams())
          .then(() => {
            this.loaded = true;
            def.resolve();
          }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    reload() {
      const def = new $.Deferred();

      if (this.loaded) this.unload();

      this.load()
        .then(def.resolve, def.reject);

      return def;
    },

    _getParams() {
      return {
        params: {
          start: 0,
          limit: 0,
        },
        add: true,
        remove: false,
        merge: true,
      };
    },

    unload() {
      this.loaded = false;
      const cache = this.getCacheStorage();
      if (cache) {
        cache.cleanup(); // clean the cache to not load old profile
      }
    },

    getByAlias(alias) {
      return this.findWhere({
        alias,
        module_name: 'ProductsModule',
      });
    },

    getIdByAlias(alias) {
      const model = this.getByAlias(alias);
      if (model) {
        return model.get('id');
      }
    },

    getDefaultId() {
      return this.getIdByAlias(this.priceAliasDefault);
    },

  });

  return new Collection();
});
