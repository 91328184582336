define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, NotAcl) => AclRouter.extend({

  appRoutes: {
    'suppliers/order-suggestion/:mode': 'listOrderSuggestion',
    'supplier-orders': 'listOrders',
    'supplier-orders/details/:id': 'viewOrder',
    'supplier-orders/dropshipment/bulk/:id': 'bulkCreateDropshipment',
    'supplier-products': 'listProducts',
  },

  acls: [
    new LoggedIn(),
    new CommerceAuthAcl({ alias: CommerceAuthAcl.orderAlias }),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderRequestManagerAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/suppliers'], callback);
  },
}));
