define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, NotAcl) => AclRouter.extend({

  appRoutes: {
    'repairs/create(/:relation_data_id)': 'newRepair',
    repairs: 'list',
    'repairs/:id': 'view',
    'repairs/:id/:swapTo': 'view',
    'repair-questions/create': 'newRepairQuestion',
    'repair-questions': 'repairQuestions',
    'repair-questions/:id': 'viewRepairQuestion',
  },

  acls: [
    new LoggedIn(),
    new CommerceAuthAcl({ alias: CommerceAuthAcl.orderAlias }),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderRequestManagerAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/repairs'], callback);
  },
}));
