define([
  'modules/common/subscribers/subscriber',
  'modules/pwa/events/worker/sourcesUpdate',
  'modules/common/components/locale',
  'toastr',
], (Subscriber, SourcesUpdateEvent, Locale, Toastr) => {
  let isOpen = false;
  const showToaster = (event) => {
    isOpen = true;
    Toastr.warning(
      Locale.translate('please_reload_the_page'),
      Locale.translate('new_version'),
      {
        timeOut: 0,
        extendedTimeOut: 0,
        closeButton: true,
        onclick() {
          event.doUpdate();
        },
        onHidden() {
          isOpen = false;
        },
      },
    );
  };
  const onUpdate = (event) => {
    if (!isOpen) {
      showToaster(event);
    }
  };

  return Subscriber.extend({
    events: [{
      event: SourcesUpdateEvent,
      action: onUpdate,
    }],
  });
});
