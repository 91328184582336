define([
  'jquery',
  'upx.modules/ShopModule/models/Configuration',
  'upx.modules/ShopModule/collections/Configuration',
], (
  $, ShopConfigurationModel, ShopConfigurationCollection,
) => {
  const Model = ShopConfigurationModel.extend({

    offline: true,

    loaded: false,

    load(force) {
      const def = new $.Deferred();

      if (force) this.unload();

      if (!this.loaded) {
        const collection = new ShopConfigurationCollection();
        const parameters = {
          start: 0,
          limit: 1,
          filters: [{
            name: 'is_default__=',
            val: true,
          }],
        };

        collection.fetch({ params: parameters })
          .then(() => {
            const model = collection.first();
            if (model) this.set(model.toJSON());
            this.loaded = true;
            def.resolve();
          }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    unload() {
      this.clear();
      this.getCacheStorage().cleanup();
      this.loaded = false;
    },

    update(data) {
      const def = new $.Deferred();

      const model = new ShopConfigurationModel({
        ...data,
        id: this.get('id'),
      });

      model.save()
        .then(def.resolve, def.reject);

      return def;
    },

    /**
         * Helper functions
         */

    getCurrencyIso3() {
      return this.get('currency_iso3');
    },

    getDefaultTaxRateId() {
      return this.get('tax_rate_default_id');
    },

    getPaymentTaxRateId() {
      return this.get('tax_rate_default_payment_id');
    },

    getShipmentTaxRateId() {
      return this.get('tax_rate_default_shipment_id');
    },

    getStockValuationPercentage() {
      return ((this.get('stock_valuation_use_fraction') || 0) * 100).toFixed(2);
    },

    getFallbackShippingMethodId() {
      return this.get('synchronisation_fallback_shipping_method_id');
    },
    getStartOfTheDay() {
      const startOfTheDay = this.get('start_of_the_day');
      let hours = 0;
      let minutes = 0;

      if (startOfTheDay) {
        [hours, minutes] = startOfTheDay.split(':');
      }

      return {
        hours: parseInt(hours),
        minutes: parseInt(minutes),
      };
    },

  });

  return new Model();
});
