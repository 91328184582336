define([
  'application',
  'underscore',
  'backbone',
  'modules/common/components/managers/configuration',
  'modules/common/subscribers/subscriber',
  'modules/upx/events/user/loggedOut',
  'modules/upx/events/route/unauthorized',
  'modules/admin/events/header/signOut',

  'modules/common/components/historyBreadcrumb',
  'modules/profile/models/profile',
  'modules/admin/models/dashboard',
  'modules/common/components/managers/menu',
  'modules/upx/collections/users',
  'modules/shop/components/onboardflow',
  'modules/shop/components/sessionStorage',
  'modules/shop/collections/FavouriteApplication',
],
(App, _, Backbone, ConfigurationManager, Subscriber, LoggedOutEvent, UnauthorizedEvent, HeaderSignOutEvent,
  HistoryBreadcrumb, ProfileModel, DashboardModel, MenuManager, Users, OnboardFlow, SessionStorage, FavouriteApplication) => {
  const onSignOut = function (event) {
    ProfileModel.unload();
    DashboardModel.unload();
    OnboardFlow.unload();
    SessionStorage.clear();
    FavouriteApplication.unload();

    const menuItems = App.com(MenuManager).get('main');
    menuItems.remove(menuItems.models);

    HistoryBreadcrumb.clean();
  };
  const onLoggedOut = function (event) {
    // Always redirect to the auth route on logout.
    Backbone.history.navigate('auth', { trigger: true });
  };

  return Subscriber.extend({
    events: [{
      event: LoggedOutEvent,
      action: onLoggedOut,
    }, {
      event: UnauthorizedEvent,
      action: onLoggedOut,
    }, {
      event: HeaderSignOutEvent,
      action: onSignOut,
    }],
  });
});
