define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',
  'modules/common/components/locale',
  'modules/upx/components/upx',

  'upx.modules/ManagementModule/models/Subscription',
  'upx.modules/ManagementModule/collections/Feature',
], (
  $, _, Backbone, Component, Locale, Upx,
  SubscriptionModel, FeatureCollection,
) => {
  const subscriptionModuleName = 'CommerceModule';
  const subscriptionAlias = 'StoreKeeper';

  const subscriptionInit = {
    module_name: subscriptionModuleName,
    alias: subscriptionAlias,
  };
  const Feature = Component.extend({

    APP_NAME_CORE_BILLING_MODULE_MARGIN: 'core.BillingModule.MarginReport',
    APP_NAME_CORE_BILLING_MODULE_INVOICING: 'core.BillingModule.Invoicing',
    APP_NAME_CORE_DOCUMENT_MODULE_INTERNAL_DOCUMENT: 'core.DocumentModule.InternalDocument',
    APP_NAME_CORE_COMMERCE_MODULE_INTRANET_FEATURE: 'core.CommerceModule.Intranet',
    APP_NAME_CORE_MY_PARCEL_NL_MODULE_MY_PARCEL_NL_PROVIDER: 'core.MyParcelNlModule.MyParcelNlProvider',
    APP_NAME_CORE_PARCEL_NL_MODULE_PARCEL_NL_PROVIDER: 'core.ParcelNlModule.ParcelNlProvider',
    APP_NAME_CORE_VELOYD_PARCEL_MODULE_VELOYD_PARCEL_MODULE_PROVIDER: 'core.VeloydParcelModule.VeloydParcelModuleProvider',
    APP_NAME_CORE_PAYMENT_MODULE_GIFT_CARD: 'core.PaymentModule.GiftCard',
    APP_NAME_CORE_PAYMENT_MODULE_PAYNL: 'core.PaymentModule.PayNl',
    APP_NAME_CORE_PAYMENT_MODULE_CCV_PIN: 'core.PaymentModule.CCVPin',
    APP_NAME_CORE_POST_NL_MODULE_POST_NL_PROVIDER: 'core.PostNlModule.PostNlProvider',
    APP_NAME_CORE_PRODUCTS_MODULE_PRODUCT_STOCK: 'core.ProductsModule.ProductStock',
    APP_NAME_CORE_SHOP_MARKETING_MODULE_MAILCHIMP: 'core.ShopMarketingModule.Mailchimp',
    APP_NAME_CORE_PAYMENT_MODULE_GROUPCARD: 'core.PaymentModule.GroupCard',
    APP_NAME_CORE_PAYMENT_MODULE_CCV_LAB_GIFT_CARD: 'core.PaymentModule.CCVLabGiftCard',
    APP_NAME_CORE_SHOP_MODULE_CUSTOMER_APPOINTMENT: 'core.ShopModule.CustomerAppointment',
    APP_NAME_CORE_SHOP_MODULE_CUSTOMER_PRICE_LIST: 'core.ShopModule.CustomerPriceList',
    APP_NAME_CORE_SHOP_MODULE_MEMBER_CARD: 'core.ShopModule.MemberCard',
    APP_NAME_CORE_SHOP_MODULE_SUPPLIER: 'core.ShopModule.Supplier',
    APP_NAME_CORE_SHOP_MODULE_REPAIRS: 'core.ShopModule.Repairs',
    APP_NAME_CORE_SHOP_MODULE_QUOTES: 'core.ShopModule.Quote',
    APP_NAME_CORE_SHOP_MODULE_NARROWCAST: 'core.ShopModule.Narrowcast',
    APP_NAME_CORE_SHOP_USED_GOODS: 'core.ShopModule.UsedGoods',
    APP_NAME_CORE_SUPPORT_MODULE_SUPPORT_REQUEST: 'core.SupportModule.SupportRequest',
    APP_NAME_CORE_PRODUCTS_MODULE_CONFIGURABLE_PRODUCT: 'core.ProductsModule.ConfigurableProduct',
    APP_NAME_CORE_PRODUCTS_MODULE_IMAGE_IMPORT: 'core.ProductsModule.ImageImport',
    APP_NAME_CORE_PRODUCTS_MODULE_PRODUCT_SERIAL: 'core.ProductsModule.ProductStockSerial',
    APP_NAME_CORE_PRODUCTS_MODULE_PRODUCT_ADDON: 'core.ProductsModule.ProductAddOn',
    APP_NAME_CORE_PAYMENTS_MODULE_SUM_UP: 'core.PaymentModule.SumUp',
    APP_NAME_CORE_EMAIL_MODULE_EMAIL: 'core.EmailModule.Email',
    APP_NAME_CORE_SHOP_MODULE_SUPPLIER_ORDER: 'core.ShopModule.SupplierOrder',
    APP_NAME_CORE_SHOP_MODULE_LOYALTY_PROGRAM: 'core.ShopModule.LoyaltyProgram',
    APP_NAME_CORE_FISKALY_MODULE_FISKALY: 'core.FiskalyModule.Fiskaly',
    APP_NAME_CORE_TWINFIELD_INTEGRATION: 'core.BillingIntegrationModule.TwinfieldIntegration',
    APP_NAME_CORE_SNELSTART_INTEGRATION: 'core.BillingIntegrationModule.SnelStartIntegration',
    APP_NAME_CORE_EXACT_ONLINE_INTEGRATION: 'core.BillingIntegrationModule.ExactOnlineIntegration',
    APP_NAME_CORE_EMAILONINVOICE_INTEGRATION: 'core.BillingIntegrationModule.EmailOnInvoiceIntegration',
    APP_NAME_CORE_SHOP_MODULE_WEB_SHOP_COUNT: 'core.ShopModule.WebStoreCount',
    APP_NAME_CORE_SHOP_MODULE_POS_SHOP_COUNT: 'core.ShopModule.PosStoreCount',
    APP_CORE_SHOP_MODULE_SHOP_PRODUCT_COUNT: 'core.ShopModule.ShopProductCount',
    APP_CORE_SHOP_MODULE_BACKOFFICE_ORDER_PROCESSING: 'core.ShopModule.BackofficeOrderProcessing',
    APP_CORE_PAYMENT_MODULE_SK_PAY_PIN_TERMINAL: 'core.PaymentModule.SkPayPinTerminal',
    APP_CORE_SHOP_MODULE_DEVICE: 'core.ShopModule.Device',
    APP_CORE_SHOP_MODULE_POS_STORE_COUNT: 'core.ShopModule.PosStoreCount',
    APP_CORE_IMPORT_EXPORT_MODULE_IMPORT_EXPORT_CENTER: 'core.ImportExportModule.ImportExportCenter',
    APP_CORE_PRODUCTS_MODULE_PRODUCT_ATTRIBUTE_CATEGORIES: 'core.ProductsModule.ProductAttributeCategories',
    APP_CORE_PRODUCTS_MODULE_MERGE_PRODUCT_ATTRIBUTE_INTO_ANOTHER: 'core.ProductsModule.MergeProductAttributeIntoAnother',
    APP_CORE_PRODUCTS_MODULE_PRODUCT_STOCK_VARIATION_REPORT: 'core.ProductsModule.ProductStockVariationReport',
    APP_CORE_PRODUCTS_MODULE_SALES_CHANNEL_TURNOVER_OVERVIEW: 'core.ProductsModule.SalesChannelTurnoverOverview',
    APP_CORE_BILLING_MODULE_INTRACOMMUNITY_REPORT: 'core.BillingModule.IntracommunityReport',
    APP_CORE_BILLING_MODULE_OUTSIDE_EUROPE_VAT_REPORT: 'core.BillingModule.OutsideEuropeVatReport',
    APP_CORE_SHOP_MODULE_CASH_MUTATIONS: 'core.ShopModule.CashMutations',
    APP_CORE_SHOP_MODULE_RELATION_CONTACTS: 'core.ShopModule.RelationContacts',
    APP_CORE_SHOP_MODULE_COUPON_CODES: 'core.ShopModule.CouponCodes',
    APP_CORE_SHOP_MODULE_BRAND: 'core.ShopModule.Brand',
    APP_CORE_SHOP_MODULE_SEASONS: 'core.ShopModule.Seasons',
    APP_CORE_SHOP_MODULE_FASHION_COLLECTIONS: 'core.ShopModule.FashionCollections',
    APP_CORE_SHOP_MODULE_FEATURED_ATTRIBUTE_CATEGORIES: 'core.ShopModule.FeatuedAttributeCategories',
    APP_CORE_SHOP_MODULE_MERGE_PRODUCTS_INTO_CONFIGURABLE: 'core.ShopModule.MergeProductsIntoConfigurable',
    APP_CORE_SHOP_MODULE_SHIPMENT_PARCEL_OVERVIEW: 'core.ShopModule.ShipmentParcelOverview',
    APP_CORE_SHOP_MODULE_PAYMENT_STATISTICS: 'core.ShopModule.PaymentStatistics',
    APP_CORE_SHOP_MODULE_ORDER_PAYMENTS: 'core.ShopModule.OrderPayments',
    APP_CORE_SHOP_MODULE_POS_JOURNALS: 'core.ShopModule.POSJournals',
    APP_CORE_SHOP_MODULE_PRODUCT_LABEL_PRINTING: 'core.ShopModule.ProductLabelPrinting',
    APP_CORE_SHOP_MODULE_SALES_CHANNELS_MANAGEMENT: 'core.ShopModule.SalesChannelsManagement',
    APP_CORE_SHOP_MODULE_SCHEDULED_DISCOUNTS: 'core.ShopModule.ScheduledDiscounts',
    APP_CORE_SHOP_MODULE_STOCK_TURNOVER_ANALYSIS: 'core.ShopModule.StockTurnoverAnalysis',
    APP_CORE_COMMERCE_MODULE_WINKEL_STRAAT_SHOP: 'core.CommerceModule.WinkelStraatShop',
    APP_CORE_SHOP_MODULE_ORDER_REQUEST_SALES_CHANNEL: 'core.ShopModule.OrderRequestSalesChannel',
    APP_CORE_ONLINE_FOOD_ORDER_MODULE_SITE_DISH: 'core.OnlineFoodOrderModule.SiteDish',
    APP_CORE_ONLINE_FOOD_ORDER_MODULE_LOCALTOMORROW: 'core.OnlineFoodOrderModule.LocalTomorrow',
    APP_CORE_DOCUMENT_MODULE_SHARED_DOCUMENT: 'core.DocumentModule.SharedDocument',
    APP_CORE_PRODUCTS_MODULE_PRODUCT_IMAGE_CATEGORIES: 'core.ProductsModule.ProductImageCategories',
    APP_CORE_SHOP_MODULE_P_O_S_JOURNALS: 'core.ShopModule.POSJournals',
    APP_CORE_COMMERCE_MODULE_ANALYTICS_LEGACY2020: 'core.CommerceModule.AnalyticsLegacy2020',
    APP_CORE_COMMERCE_MODULE_ONLINE_SK_PAYMENTS_LEGACY2020: 'core.CommerceModule.OnlineSkPaymentsLegacy2020',
    APP_CORE_COMMERCE_MODULE_CUSTOMER_TERM_AND_CONDITIONS: 'core.CommerceModule.CustomerTermAndConditions',
    APP_CORE_TRANSLATION_MODULE_TRANSLATION_CENTER: 'core.TranslationModule.TranslationCenter',
    APP_CORE_SHOP_MODULE_SK_AS_C_C_V_SHOP: 'core.ShopModule.SkAsCCVShop',
    APP_CORE_SHOP_MODULE_SK_AS_MAGENTO2: 'core.ShopModule.SkAsMagento2',
    APP_CORE_SHOP_MODULE_SK_AS_PRESTA_SHOP: 'core.ShopModule.SkAsPrestaShop',
    APP_CORE_SHOP_MODULE_SK_AS_SHOPIFY: 'core.ShopModule.SkAsShopify',
    APP_CORE_SHOP_MODULE_SK_AS_SHOP_TRADER: 'core.ShopModule.SkAsShopTrader',
    APP_CORE_SHOP_MODULE_SK_AS_WOO_COMMERCE: 'core.ShopModule.SkAsWooCommerce',
    APP_CORE_SHOP_MODULE_SK_AS_SHOPWARE6: 'core.ShopModule.SkAsShopware6',
    APP_CORE_SHOP_MODULE_SK_AS_P_O_S: 'core.ShopModule.SkAsPOS',
    APP_CORE_SHOP_MODULE_PAYMENT_STATISTICS_FEATURE: 'core.ShopModule.PaymentStatisticsFeature',
    APP_CORE_PRODUCTS_MODULE_PRODUCT_ATTRIBUTE: 'core.ProductsModule.ProductAttribute',
    APP_CORE_BILLING_MODULE_PRODUCT_GROUP: 'core.BillingModule.ProductGroup',
    APP_CORE_SHOP_MODULE_HOSPITALITY: 'core.ShopModule.Hospitality',
    APP_CORE_SHOP_MODULE_DEVICE_SETTING: 'core.ShopModule.DeviceSetting',
    APP_CORE_FILE_MODULE_IMAGE_CDN: 'core.FileModule.ImageCdn',
    APP_NAME_SHOP_MODULE_FLORA_AT_HOME_INTEGRATION: 'core.ShopModule.FloraAtHomeIntegration',
    APP_NAME_SHOP_MODULE_EDC_INTEGRATION: 'core.ShopModule.EDCIntegration',
    APP_NAME_CORE_SHOP_MODULE_DROPSHIPMENT: 'core.ShopModule.Dropshipment',
    APP_NAME_CORE_SHOP_MODULE_FASHION_UNITED_INTEGRATION: 'core.ShopModule.FashionUnitedIntegration',
    APP_NAME_CORE_PRODUCTS_MODULE_PRODUCT_VALUATION: 'core.ProductsModule.ProductValuation',
    APP_NAME_CORE_REPORT_MODULE_REPORTS: 'core.ReportModule.Reports',
    APP_NAME_CORE_SHOP_MODULE_ORDER_WRITER_INTEGRATION: 'core.ShopModule.OrderWriterIntegration',

    APP_CORE_SHOP_MODULE_SHOP_PRODUCT_FEED: 'core.ShopModule.ShopProductFeed',

    initialize() {
      this.subscription = new SubscriptionModel(subscriptionInit);
      this.featureCollection = new FeatureCollection();
    },

    getInstallDate() {
      this.checkLoaded();
      return new Date(this.subscription.get('date_created'));
    },

    loadDeferred: false,

    isLoaded() {
      return this.loadDeferred && this.loadDeferred.state() === 'resolved';
    },
    isLoading() {
      return this.loadDeferred && this.loadDeferred.state() === 'pending';
    },

    load(force) {
      if (this.isLoading()) {
        return this.loadDeferred;
      }
      const def = new $.Deferred();
      def.fail(() => this.unload());

      if (force || !this.isLoaded()) {
        this.unload();

        this.subscription.getDetailsByAlias().then(
          (data) => {
            const features = data.features || [];
            this.featureCollection.add(features);
            delete data.features;
            this.subscription.set(data);

            def.resolve();
          },
          def.reject,
        );
      } else {
        def.resolve();
      }
      this.loadDeferred = def.promise();
      return this.loadDeferred;
    },

    unload() {
      this.featureCollection.reset();
      this.subscription.clear({ silent: true });
      this.subscription.set(subscriptionInit);
      this.loadDeferred = false;
      this.trigger('unload');
    },

    checkLoaded() {
      if (!this.isLoaded()) {
        throw new Error('Subscription data is not loaded.');
      }
    },

    getFeatureByAppName(name) {
      this.checkLoaded();
      return this.featureCollection.findWhere({ 'feature_type.name': name });
    },

    getFeatureActiveByAppName(name) {
      this.checkLoaded();
      const feature = this.getFeatureByAppName(name);
      if (feature) {
        return feature.get('active');
      }
      return false;
    },
    getFeatureNames() {
      this.checkLoaded();
      return this.featureCollection.pluck('feature_type.name');
    },

    changeFeatureStatus(name, active) {
      this.checkLoaded();
      active = !!active;
      const def = new $.Deferred();
      let needsChange = true;
      const feature = this.getFeatureByAppName(name);
      if (feature) {
        needsChange = active !== feature.get('active');
      }
      if (needsChange) {
        Upx.call('ManagementModule', 'changeFeatureStatus',
          {
            fields: {
              feature_type_name: name,
              active: active ? 1 : 0,
            },
          }).then(
          () => {
            if (feature) {
              feature.set('active', active);
              def.resolve();
            } else {
              const subscription = new SubscriptionModel(subscriptionInit);
              subscription.getDetailsByAlias({
                feature_filters: [
                  {
                    name: 'name__=',
                    val: name,
                  },
                ],
              }).then(
                () => {
                  const features = subscription.get('features') || [];
                  this.featureCollection.add(features, {
                    merge: true,
                  });
                  def.resolve();
                },
                def.reject,
              );
            }
          },
          def.reject,
        );
      } else {
        def.resolve();
      }
      return def.promise();
    },

    isLoyaltyFeatureEnabled() {
      return this.getFeatureActiveByAppName(this.APP_NAME_CORE_SHOP_MODULE_LOYALTY_PROGRAM);
    },

  });

  return new Feature();
});
