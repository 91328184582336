define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, NotAcl) => AclRouter.extend({

  appRoutes: {
    'used-goods': 'list',
  },

  acls: [
    new LoggedIn(),
    new CommerceAuthAcl({ alias: CommerceAuthAcl.orderAlias }),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderRequestManagerAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/usedGoods'], callback);
  },
}));
