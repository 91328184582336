define([
  'modules/upx/components/upx',
], (
  Upx,
) => ({

  booted: false,

  load() {
    if (!this.booted) {
      Upx.call('CommerceModule', 'getOnboardFlowCustomer').then(
        (result) => {
          if (result.active) {
            const { data } = result;
            window.onboardFlowSettings = {
              site_key: data.site_key,
              user: {
                id: data.user_id,
                customer_id: data.customer_id,
                email: data.customer_email,
                has_payment_method: data.customer_has_payment_method,
              },
              custom_properties: data.custom_properties,
            };
            (function () {
              const po = document.createElement('script');
              po.type = 'text/javascript';
              po.async = true;
              po.src = 'https://media.onboardflow.com/gen/tracker/vOFevNl8.min.js';
              po.onload = po.onreadystatechange = function () {
                const rs = this.readyState;
                if (rs && rs != 'complete' && rs != 'loaded') return;
                OnboardFlowLoader = new OnboardFlowLoaderClass();
                OnboardFlowLoader.identify(window.onboardFlowSettings);
              };
              const s = document.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(po, s);
            }());
          } else {
            console.warn('OnboardFlow is not active for this user/account');
          }
        },
        () => {
          window.onboardFlowSettings = {};
          console.error('Failed to get OnboardFlow configuration');
        },
      );
      this.booted = true;
    }
  },

  updateData() {
    if (this.booted && window.OnboardFlowLoader) {
      window.OnboardFlowLoader.updateIdentity(window.onboardFlowSettings);
    }
  },

  unload() {
    window.onboardFlowSettings = {};
    this.booted = false;
    this.updateData();
  },
}));
