define([
  'jquery',
  'module',
  'upx.modules/CommerceModule/collections/Questionnaire',
],
(
  $, Module, UpxCollection,
) => {
  const model = UpxCollection.extend({

    completed: false,
    loaded: false,

    load(force) {
      let def = $.Deferred();
      if (!this.loaded || force) {
        const params = {
          params: {
            start: 0,
            limit: 1,
            filters: [{
              name: 'alias__=',
              val: 'setup',
            }],
          },
        };
        def = this.fetch(params);
        const self = this;
        def.then(() => {
          self.completed = !!self.length;
        });
      } else {
        def.resolve();
      }
      return def.promise();
    },

    unload() {
      this.clear();
      this.getCacheStorage().cleanup();
    },

    isSetupCompleted() {
      return this.completed;
    },

    setSetupCompleted() {
      this.completed = true;
    },
  });
  return new model();
});
