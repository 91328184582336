define([], (
) => ({

  storage: {},

  get(key) {
    return this.storage[key];
  },

  set(key, value) {
    this.storage[key] = value;
  },

  unset(key) {
    delete this.storage[key];
  },

  clear() {
    this.storage = {};
  },

  KEY_APP_SEARCH_QUERY: 'app-search-query',
  KEY_REPORT_SEARCH_QUERY: 'report-query',

}));
