define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, NotAcl) => AclRouter.extend({

  appRoutes: {
    'quote-request': 'list',
    'quote-request/edit/:id': 'edit',
    'quote-request/details/:id(/:swapTo)(/:swapToChild)': 'details',
    'quote-request/details-contract/:id(/:swapTo)(/:swapToChild)': 'detailsContract',
  },

  acls: [
    new LoggedIn(),
    new CommerceAuthAcl({ alias: CommerceAuthAcl.orderAlias }),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderRequestManagerAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/quoteRequest'], callback);
  },
}));
