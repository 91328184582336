define([
  'modules/profile/models/profile',
  'modules/common/components/historyBreadcrumb',
  'application',
], (
  ProfileModel, HistoryBreadcrumb, App,
) => {
  const GA_ID = 'UA-144771113-1';
  const DIM_ACCOUNT = 'dimension1';
  const DIM_VERSION = 'dimension2';
  const DIM_USER_LEVEL = 'dimension3';

  return {

    init() {
      // the
      (function (i, s, o, g, r, a, m) {
        i.GoogleAnalyticsObject = r; i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments);
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
        m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m);
      }(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga'));

      ga('create', GA_ID, 'auto');
      setTimeout(() => {
        // setTimeout to make sure it never breaks the app
        ga('set', DIM_ACCOUNT, App.settings.upx.account);
        if (!!window.storekeeper && 'version' in window.storekeeper) {
          ga('set', DIM_VERSION, window.storekeeper.version);
        } else {
          ga('set', DIM_VERSION, 'development');
        }
      });
    },

    setUser() {
      setTimeout(() => {
        // setTimeout to make sure it never breaks the app

        ga('set', 'userId', ProfileModel.get('relation_data_profile.shortname'));
        const member = ProfileModel.getProductWithMember('CommerceModule', 'Commerce');
        if (member && member.membership) {
          ga('set', DIM_USER_LEVEL, member.membership.group_member_type.alias);
        }
      });
    },

    sendCurrentPage() {
      setTimeout(() => {
        // setTimeout to make sure it never breaks the app
        ga('send', 'pageview', HistoryBreadcrumb.getCurrent());
      });
    },
  };
});
