define([
  'jquery',
  'underscore',
  'backbone',

  'upx.modules/TodoModule/collections/TaskType',
  'modules/common/components/locale',
], (
  $, _, Backbone,
  Collection, Locale,
) => {
  const collection = Collection.extend({

    offline: true,

    loaded: false,

    basic: 'Basic',
    call: 'Call',
    deadline: 'Deadline',
    meeting: 'Meeting',

    load() {
      const def = new $.Deferred();

      if (this.loaded) {
        def.resolve();
      } else {
        this.fetchAll()
          .then(def.resolve, def.reject);

        const self = this;
        def.then(
          () => {
            self.processCollection();
            self.loaded = true;
          },
          (response) => {
            self.loaded = false;
            response = Locale.translate('failed_to_load_task_types');
          },
        );
      }

      return def.promise();
    },

    processCollection() {
      const self = this;
      this.each((model) => {
        self._setIcon(model);
        self._setTitle(model);
      });
    },

    _setIcon(model) {
      const alias = model.get('alias');
      switch (alias) {
        case this.basic:
          model.set('icon', 'fa-thumb-tack');
          break;
        case this.call:
          model.set('icon', 'fa-phone');
          break;
        case this.deadline:
          model.set('icon', 'fa-clock-o');
          break;
        case this.meeting:
          model.set('icon', 'fa-users');
          break;
        default:
          model.set('icon', 'fa-thumb-tack');
          break;
      }
    },

    _setTitle(model) {
      const alias = model.get('alias');
      switch (alias) {
        case this.basic:
          model.set('title', Locale.translate('basic'));
          break;
        case this.call:
          model.set('title', Locale.translate('call'));
          break;
        case this.deadline:
          model.set('title', Locale.translate('deadline'));
          break;
        case this.meeting:
          model.set('title', Locale.translate('meeting'));
          break;
        default:
          model.set('title', Locale.translate('task_type'));
          break;
      }
    },

    reload() {
      if (this.loaded) {
        this.unload();
      }

      return this.load();
    },

    unload() {
      const cache = this.getCacheStorage();
      if (cache) {
        cache.cleanup();
      }

      this.reset();

      this.loaded = false;
    },

    fetchAll() {
      return this.fetch({
        params: {
          start: 0,
          limit: 0,
        },
        add: true,
        remove: false,
        merge: true,
      });
    },

  });

  return new collection();
});
