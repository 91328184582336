define([
  'jquery',
  'module',
  'upx.modules/BlogModule/collections/TranslatedAttributeOption',
  'modules/common/components/locale',
],
(
  $, Module, UpxCollection, Locale,
) => {
  const Collection = UpxCollection.extend({

    offline: false,

    lastUpdateField: 'date_updated',

    loadParameters: {
      start: 0,
      limit: 2500,
      lang: Locale.getLocale(),
      sort: [{ name: 'order', dir: 'asc' }],
    },

    fetchSelectedAttributes(attributeIds, force = false) {
      if (this.isLoading()) {
        // if it's already loading than return the main deferred,
        // it will load those attributes as well
        return this.loadDeferred;
      }

      if (force || !this.isLoaded()) {
        return this.fetchAll({
          params: {
            ...this.loadParameters,
            filters: [{
              name: 'attribute_id__in_list',
              multi_val: attributeIds,
            }],
          },
          add: true,
          remove: false,
          merge: true,
        });
      }
      return new $.Deferred().resolve();
    },

    removeOne(id) {
      this.remove(id);
    },

    labelContains(text) {
      return this.filter(this.labelContainsFilterFn(text));
    },

    getCollectionByAttributeIdWithText(attributeId, text) {
      const labelFn = this.labelContainsFilterFn(text);
      return new Collection(
        this.filter(
          (model) => model.get('attribute_id') == attributeId && labelFn(model),
        ),
      );
    },

    getCollectionByAttributeId(attributeId) {
      return new Collection(this.filter((model) => model.get('attribute_id') == attributeId));
    },

    /**
             * get function for filtering by label
             * @param text
             * @returns {Function}
             */
    labelContainsFilterFn(text) {
      text = text.toLowerCase();
      return function (model) {
        // indexOf(-1) -> not found -> return 0 -> false
        // other are true
        return ~model.get('label').toLowerCase().indexOf(text);
      };
    },
    /**
             * @description Checks if the attribute exists with a similar name, returns false when not loaded.
             * @return {boolean}
             */
    existsByName(name) {
      if (!this.loaded) {
        return false;
      }

      return !!this.findWhere({
        name,
      });
    },

    makeSelectOptionText(model) {
      const label = model.get('label') || '';
      const name = model.get('name') || '';

      const text = name.toLocaleLowerCase() !== label.toLocaleLowerCase()
        ? `${label} (${name})`
        : label;
      return text;
    },

    makeAttributeSelectOption(model) {
      const text = this.makeSelectOptionText(model);
      return {
        id: model.get('id'),
        text,
      };
    },
  });

  return new Collection();
});
