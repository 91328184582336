define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/shop/components/commerceAuth',
], (AclRouter, LoggedIn, CommerceAuthAcl, CommerceAuth) => AclRouter.extend({

  appRoutes: {
    order: 'overview',
    'order/details/:order_id(/:swap_to)': 'details',
    'order/create(/:relation_data_id)': 'create',
    'order/edit/:id': 'edit',
    'order/filtered/:cacheId': 'filteredOverview',

    // Random other items
    'order/setting/:additional': 'overviewSetting',
    'order/create-from-quote/:quote_id': 'createOrderFromQuote',
    'order/create-from-repair/:repair_id': 'createOrderFromRepair',
    'order/copy-from-order/:order_id': 'copyFromOrder',
  },

  acls: [
    new LoggedIn(),
    new CommerceAuthAcl({ alias: CommerceAuthAcl.orderAlias }),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/orders'], callback);
  },
}));
