define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, NotAcl) => AclRouter.extend({

  appRoutes: {
    'scheduled-discounts': 'overview',
    'scheduled-discounts/create': 'create',
    'scheduled-discounts/details/:id/:tabName': 'details',
    'scheduled-discounts/details/:id': 'details',
    'scheduled-discounts/edit/:id': 'edit',
    'scheduled-discounts/delete/:id': 'delete',
  },

  acls: [
    new LoggedIn(),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderRequestManagerAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/scheduledDiscounts'], callback);
  },

}));
