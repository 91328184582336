define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, NotAcl) => AclRouter.extend({

  appRoutes: {
    suppliers: 'listSuppliers',
    'suppliers/add': 'addSupplier',
    'suppliers/add/fashion-united/:glnId': 'addSupplier',
    'suppliers/add/order-writer/:glnId': 'addSupplier',

    customers: 'listCustomers',
    'consumers/add': 'addConsumer',
    'companies/add': 'addCompany',

    contacts: 'listContacts',
    'contacts/addConsumer': 'addContactConsumer',
    'contacts/addCompany': 'addContactCompany',

    'relations/details/:id': 'details',
    'relations/details/:id/reload': 'detailsReload', // with advise from Ian
    'relations/details/:id/:tabName': 'details',
    'relations/details/:id/:tabName(/:tabParam1)(/:tabParam2)': 'details',
    'relations/password/:id': 'changePassword',
    'relations/delete/:id': 'remove',
  },

  acls: [
    new LoggedIn(),
    new CommerceAuthAcl({ alias: CommerceAuthAcl.relationAlias }),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
  ],

  overwriteAcls: [{
    routes: [
      'customers',
      'consumers/add',
      'companies/add',

      'relations/details/:id',
      'relations/details/:id/reload',
      'relations/details/:id/:tabName',
    ],
    acls: [
      new LoggedIn(),
      new CommerceAuthAcl({ alias: CommerceAuthAcl.relationAlias }),
    ],
  }],

  getController(callback) {
    return require(['modules/shop/controllers/relations'], callback);
  },
}));
