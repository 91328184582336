define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',
  'modules/common/components/browserDetection',
  'modules/shop/components/popups',
  'modules/shop.common/components/webMode',
  'modules/shop/components/mode',
], (
  $, _, Backbone, Locale, BrowserDetection, Popups, WebModeComponent, Mode,
) => ({

  check() {
    // Only if the browser is not chrome, is in web mode, or has no mode selected
    if (!BrowserDetection.isChrome && (WebModeComponent.isInWebMode() || Mode.getMode() === null)) {
      let downloadUrl = 'https://www.google.com/chrome/';
      const downloadText = Locale.translate('click_to_download_google_chrome');
      const warning = Locale.translate('storekeeper_works_best_within_the_google_chrome_webbrowser_we_kindly_recommend_you_to_use_google_chrome_for_the_best_user_experience_exclamation');

      if (Locale.getLocale() === 'nl') {
        downloadUrl = 'https://www.google.nl/chrome/';
      }

      const text = `${warning}
				<br>
				<br>
				<a href="${downloadUrl}" target="_blank">${downloadText}</a>`;

      $('#incompatible-browser').html(text);

      // Show popup
      Popups.information(() => text, {
        headerText: Locale.translate('unsupported_browser'),
      });
    }
  },

}));
