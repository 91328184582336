define([
  'modules/common/components/component',

  'modules/pwa/events/worker/load',

], (
  Component, LoadEvent,
) => {
  const Worker = Component.extend({

    worker: null,

    getWorker() {
      if (!this.worker) {
        throw new Error('Worker not registered');
      }
      return this.worker;
    },

    register(worker) {
      this.worker = worker;
      const event = new LoadEvent();
      event.trigger();
    },

  });

  return new Worker();
});
