define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop/templates/modals/simple/view.hbs',

  'modules/admin/behaviors/loader',

  'modules/common/components/locale',

  'bootstrap',
], (
  $, _, Backbone, Template,
  Loader,
  Locale,
) =>
  /**
     * Usage:
     * Show this view in a region, with view passed as option -> contentView.
     */
  Backbone.Marionette.LayoutView.extend({
    template: Template,

    className: 'modal fade modal-in-region',

    behaviors: {
      Loader: {
        behaviorClass: Loader,
      },
    },

    defaults: {
      showHeader: true,
      showTitle: true,
      showFooter: true,
      showCancel: true,
      showCancelTop: null,
      showAccept: true,

      triggerOnView: true,
      triggerMethodOnView: true,
      acceptCloses: true,
      cancelCloses: true,

      titleIcon: false,
      modalSize: '', // modal-xlg OR modal-lg OR modal-sm

      titleText: Locale.translate('modal'),
      cancelText: Locale.translate('cancel'),
      cancelClass: 'btn btn-default',
      cancelIconCls: null,
      acceptText: Locale.translate('accept'),
      acceptClass: 'btn btn-success',
      acceptIconCls: null,
      acceptIsValid: () => true, // true if valid and can be clicked, false otherwise
      contentText: Locale.translate('are_you_sure_question'),

      alertView: false, // An initialized view
      contentView: false, // An initialized view

      modalOptions: {
        backdrop: 'static',
        keyboard: 'true',
        show: false,
      },
    },

    ui() {
      const data = {
        // regions
        alertBox: '[data-region="alert-box"]',
        content: '[data-region="content"]',
      };
      data.accept = `[data-action="modal-accept-${this.cid}"]`;
      data.cancel = `[data-action="modal-cancel-${this.cid}"]`;
      return data;
    },

    regions: {
      alertBox: '@ui.alertBox',
      content: '@ui.content',
    },

    events: {
      'click @ui.accept': 'acceptClicked',
      'click @ui.cancel': 'cancelClicked',
    },

    acceptClicked() {
      const isValid = this.options.acceptIsValid();
      if (isValid) {
        this.triggerEvent('modal:accept');

        if (this.options.acceptCloses) {
          this.close();
        }
      } else {
        console.warn('Modal: acceptIsValid() returned false');
      }
    },

    cancelClicked() {
      this.triggerEvent('modal:cancel');

      if (this.options.cancelCloses) {
        this.close();
      }
    },

    triggerEvent(name, value) {
      if (this.options.triggerOnView) {
        const event = new $.Event(name);
        event.stopPropagation();
        this.viewsTrigger(event, value);
      }
      if (this.options.triggerMethodOnView) {
        this.triggerMethod(name, value);
      }
    },

    initialize() {
      this.options = _.extend({}, this.defaults, this.options);
      this.options.showCancelTop = _.isNull(this.options.showCancelTop)
        ? this.options.showCancel
        : this.options.showCancelTop; // backwards compatibility

      this.loadRegions = true;
    },

    onRender() {
      this.renderAlertBox();
      this.renderContent();
    },

    renderAlertBox() {
      if (this.options.alertView) {
        const region = this.getRegion('alertBox');
        region.show(this.options.alertView);

        this.loadRegions = false;
      }
    },

    renderContent() {
      if (this.options.contentView) {
        const region = this.getRegion('content');
        region.show(this.options.contentView);

        this.loadRegions = false;
      }
    },

    onShow() {
      this.initializeModal();
      this.createModalEvents();
      this.fixModalPositioning();
      this.createModalEvents();
      this.startListeningToViewEvents();
    },

    initializeModal() {
      this.modal = this.$el.modal(this.options.modalOptions);
    },

    createModalEvents() {
      const events = [ // events = https://getbootstrap.com/docs/3.3/javascript/#modals-events
        'show',
        'shown',
        'hide',
        'hidden',
        'loaded',
      ];

      const self = this;
      events.forEach((event) => {
        const bsEvent = `${event}.bs.modal`;
        const viewEvent = `modal:${event}`;
        self.modal.on(bsEvent, () => {
          self.triggerEvent(viewEvent);
        });
      });
    },

    stopModalEvents() {
      const events = [ // events = https://getbootstrap.com/docs/3.3/javascript/#modals-events
        'show',
        'shown',
        'hide',
        'hidden',
        'loaded',
      ];
      const self = this;
      events.forEach((event) => {
        const bsEvent = `${event}.bs.modal`;
        const viewEvent = `modal:${event}`;
        self.modal.off(bsEvent, () => {
          self.triggerEvent(viewEvent);
        });
      });
    },

    /**
         * Fixed the default positioning of this view from relative.
         * This will cause the view to push elements arround when rendered in a region.
         */
    fixModalPositioning() {
      this.$el.css('position', 'fixed');
    },

    startListeningToViewEvents() {
      this.viewsListeners('modal:open', this.open, true);
      this.viewsListeners('modal:close', this.close, true);
      this.viewsListeners('loader:start', this.showLoader, true);
      this.viewsListeners('loader:stop', this.stopLoader, true);
    },

    onDestroy() {
      this.stopModalEvents();
      this.stopListeningToViewEvents();
    },

    stopListeningToViewEvents() {
      this.viewsListeners('modal:open', this.open, false);
      this.viewsListeners('modal:close', this.close, false);
      this.viewsListeners('loader:start', this.showLoader, false);
      this.viewsListeners('loader:stop', this.stopLoader, false);
    },

    /*
         * Modal functions
         */

    /**
         * Opens the modal
         * When views are passed they are rendered in the modal
         * When no views are rendered it tried to render them
         * @param contentView
         * @param alertView
         */
    open(contentView, alertView) {
      if (contentView) {
        this.options.contentView = contentView;
        this.renderContent();
      }

      if (alertView) {
        this.options.alertView = alertView;
        this.renderAlertBox();
      }

      if (this.loadRegions) {
        this.renderAlertBox();
        this.renderContent();
      }

      this.triggerModal('show');
    },

    /**
         * Closes the modal
         * Can force clean the regions so the views can re-render.
         * @param clearRegions
         */
    close(clearRegions) {
      this.triggerModal('hide');

      if (clearRegions) {
        this.emptyRegions();
        this.loadRegions = true;
      }
    },

    emptyRegions() {
      this.getRegion('alertBox').reset();
      this.getRegion('content').reset();
    },

    /**
         * Toggles the open state of the modal
         */
    toggle() {
      this.triggerModal('toggle');
    },

    /**
         * Fires the 'handleUpdate' method on the modal
         */
    update() {
      this.triggerModal('handleUpdate');
    },

    /*
         * Event functions
         */

    /**
         * Fired an even on this view
         */
    selfTrigger() {
      this.trigger.apply(this, arguments);
    },

    /**
         * Fired a method on the modal
         * @param modalMethodName
         */
    triggerModal(modalMethodName) {
      this.$el.modal(modalMethodName);
    },

    /**
         * Triggers event on the views
         */
    viewsTrigger() {
      (this.options.contentView) ? this.options.contentView.trigger.call(this, arguments) : '';
      (this.options.alertView) ? this.options.alertView.trigger.call(this, arguments) : '';
    },

    /**
         * Adds or removes view events
         * @param eventName
         * @param eventFn
         * @param start
         */
    viewsListeners(eventName, eventFn, start) {
      start = start || true;
      if (start) {
        (this.options.contentView) ? this.options.contentView.on(eventName, eventFn, this) : '';
        (this.options.alertView) ? this.options.alertView.on(eventName, eventFn, this) : '';
      } else {
        (this.options.contentView) ? this.options.contentView.off(eventName, eventFn, this) : '';
        (this.options.alertView) ? this.options.alertView.off(eventName, eventFn, this) : '';
      }
    },

    showLoader() {
      return this.loaderDef = this.loader.startLoader();
    },

    stopLoader(resolveData) {
      if (this.loaderDef) {
        this.loaderDef.resolve(resolveData);
      }
    },

    serializeData() {
      const data = this.options;
      data.cid = this.cid;
      return data;
    },

  }));
