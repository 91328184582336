define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/collections/ShopType',
  'modules/common/components/locale',
], (
  $, _, Backbone, ShopTypeCollection, Locale,
) => {
  const ALIAS_WEB = 'Web';
  const ALIAS_WEB_WITH_SYNC = 'WebWithSync';
  const ALIAS_POINT_OF_SALE = 'PointOfSale';
  const ALIAS_FALLBACK = 'Fallback';
  const ALIAS_BACKOFFICE = 'Backoffice';
  const ALIAS_SITEDISH = 'Sitedish';
  const ALIAS_ORDER_REQUEST = 'OrderRequest';
  const ALIAS_WINKELSTRAAT = 'WinkelStraat';
  const ALIAS_SK_AS_POS = 'SkAsPOS';
  const ALIAS_LOCALTOMORROW = 'LocalTomorrow';

  // Shop types
  const WEBSHOP_SHOPIFY = 'shopify';
  const WEBSHOP_CCV = 'ccv-shop';
  const WEBSHOP_PRESTASHOP = 'prestashop';
  const WEBSHOP_MAGENTO2 = 'magento2';
  const WEBSHOP_SHOPTRADER = 'shoptrader';
  const WEBSHOP_WOOCOMMERCE = 'woocommerce';
  const WEBSHOP_SHOPWARE6 = 'shopware6';

  const POS_RETAIL = 'retail';
  const POS_FOOD = 'restaurant';

  const Collection = ShopTypeCollection.extend({

    loaded: false,

    fetchParameters: {
      start: 0,
      limit: 0,
    },

    load(force) {
      const def = new $.Deferred();

      // force fallback to false.
      force = _.isUndefined(force) ? false : force;

      // Unload if force is enabled
      force ? this.unload() : null;

      // If done, mark as loaded
      def.done(() => { this.loaded = true; });

      // fetch is not yet loaded
      if (!this.loaded) {
        this.fetch({ params: this.fetchParameters })
          .then(def.resolve, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    unload() {
      this.reset();
      this.loaded = false;
    },

    ALIAS_WEB,
    ALIAS_WEB_WITH_SYNC,
    ALIAS_POINT_OF_SALE,
    ALIAS_FALLBACK,
    ALIAS_BACKOFFICE,
    ALIAS_SITEDISH,
    ALIAS_ORDER_REQUEST,
    ALIAS_WINKELSTRAAT,
    ALIAS_SK_AS_POS,
    ALIAS_LOCALTOMORROW,

    POS_FOOD,
    POS_RETAIL,

    getIdByAlias(alias) {
      const model = this.findWhere({
        alias,
      });

      if (model) {
        return model.get('id');
      }
      return null;
    },

    MODULE_SHOPMODULE: 'ShopModule',

    getIdByModuleAndAlias(module, alias) {
      const model = this.findWhere({
        module_name: module,
        alias,
      });

      if (model) {
        return model.get('id');
      }
      return null;
    },

    getIconByAlias(alias, prefix) {
      prefix = prefix || 'fal';
      switch (alias) {
        case this.ALIAS_WEB:
        case this.ALIAS_SK_AS_POS:
        case this.ALIAS_WEB_WITH_SYNC:
        case this.ALIAS_WINKELSTRAAT:
          return `${prefix} fa-shopping-cart`;
        case this.ALIAS_POINT_OF_SALE:
          return `${prefix} fa-store`;
        case this.ALIAS_SITEDISH:
        case this.ALIAS_LOCALTOMORROW:
          return `${prefix} fa-hamburger`;
        case this.ALIAS_ORDER_REQUEST:
          return `${prefix} fa-bags-shopping`;
        case this.ALIAS_BACKOFFICE:
        case this.ALIAS_FALLBACK:
        default:
          return `${prefix} fa-building`;
      }
    },

    WEBSHOP_SHOPIFY,
    WEBSHOP_CCV,
    WEBSHOP_PRESTASHOP,
    WEBSHOP_MAGENTO2,
    WEBSHOP_SHOPTRADER,
    WEBSHOP_WOOCOMMERCE,
    WEBSHOP_SHOPWARE6,

    getShopNameByType(type) {
      switch (type) {
        case this.WEBSHOP_SHOPIFY:
          return 'Shopify';
        case this.WEBSHOP_CCV:
          return 'CCV Shop';
        case this.WEBSHOP_PRESTASHOP:
          return 'PrestaShop';
        case this.WEBSHOP_MAGENTO2:
          return 'Magento 2';
        case this.WEBSHOP_SHOPTRADER:
          return 'ShopTrader';
        case this.WEBSHOP_WOOCOMMERCE:
          return 'WooCommerce';
        case this.WEBSHOP_SHOPWARE6:
          return 'Shopware 6';
        default:
          console.warn('Not defined shop type', type);
          return type;
      }
    },

    getNameByAlias(alias) {
      switch (alias) {
        case this.ALIAS_SK_AS_POS:
          return Locale.translate('pos_for_webshop_sync');
        case this.ALIAS_WEB:
          return Locale.translate('web');
        case this.ALIAS_WEB_WITH_SYNC:
          return Locale.translate('webshop_with_sync');
        case this.ALIAS_BACKOFFICE:
          return Locale.translate('backoffice');
        case this.ALIAS_POINT_OF_SALE:
          return Locale.translate('point_of_sale');
        case this.ALIAS_FALLBACK:
          return Locale.translate('other');
        case this.ALIAS_SITEDISH:
          return 'SiteDish';
        case this.ALIAS_ORDER_REQUEST:
          return Locale.translate('order_request');
        case this.ALIAS_WINKELSTRAAT:
          return Locale.translate('winkelstraat');
        case this.ALIAS_LOCALTOMORROW:
          return Locale.translate('localtomorrow');
        default:
          console.warn('Not defined shop type alias', alias);
          return alias;
      }
    },

  });

  Collection.ALIAS_WEB = ALIAS_WEB;
  Collection.ALIAS_SK_AS_POS = ALIAS_SK_AS_POS;
  Collection.ALIAS_WEB_WITH_SYNC = ALIAS_WEB_WITH_SYNC;
  Collection.ALIAS_POINT_OF_SALE = ALIAS_POINT_OF_SALE;
  Collection.ALIAS_FALLBACK = ALIAS_FALLBACK;
  Collection.ALIAS_BACKOFFICE = ALIAS_BACKOFFICE;
  Collection.ALIAS_ORDER_REQUEST = ALIAS_ORDER_REQUEST;
  Collection.ALIAS_SITEDISH = ALIAS_SITEDISH;
  Collection.ALIAS_LOCALTOMORROW = ALIAS_LOCALTOMORROW;
  Collection.ALIAS_WINKELSTRAAT = ALIAS_WINKELSTRAAT;

  Collection.WEBSHOP_SHOPIFY = WEBSHOP_SHOPIFY;
  Collection.WEBSHOP_CCV = WEBSHOP_CCV;
  Collection.WEBSHOP_PRESTASHOP = WEBSHOP_PRESTASHOP;
  Collection.WEBSHOP_MAGENTO2 = WEBSHOP_MAGENTO2;
  Collection.WEBSHOP_SHOPTRADER = WEBSHOP_SHOPTRADER;
  Collection.WEBSHOP_WOOCOMMERCE = WEBSHOP_WOOCOMMERCE;
  Collection.WEBSHOP_SHOPWARE6 = WEBSHOP_SHOPWARE6;
  return new Collection();
});
