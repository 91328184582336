define([
  'application',
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',

  'modules/profile/models/profile',

], (
  App, $, _, Backbone, Component,
  ProfileModel,
) => {
  const commerceAuth = Component.extend({

    orderAlias: 'Order',
    quoteAlias: 'Quote',
    invoiceAlias: 'Invoice',
    contractAlias: 'Contract',
    fileAlias: 'File',
    productAlias: 'Product',
    relationAlias: 'Relation',
    supportAlias: 'Support',
    adminAlias: 'Admin',
    managerAlias: 'Manager',
    cashierAlias: 'Cashier',
    orderOnlyAlias: 'OrderOnly',
    orderRequestManagerAlias: 'OrderRequestManager',

    initialize() {
      this.features = [
        this.orderAlias,
        this.quoteAlias,
        this.invoiceAlias,
        this.contractAlias,
        this.fileAlias,
        this.productAlias,
        this.relationAlias,
        this.supportAlias,
        this.cashierAlias,
      ];
    },
    /**
         * featured defined in config
         */
    hasFeatureAccess(name) {
      if (this.features.indexOf(name) != -1) {
        // if is a feature
        if (!!App.settings
                    && !!App.settings.shop
                    && !!App.settings.shop.limit_to_features
                    && App.settings.shop.limit_to_features.length
        ) {
          if (App.settings.shop.limit_to_features.indexOf(name) != -1) {
            // limit set but allowed
            return true;
          }
        } else {
          // but no limit set
          return true;
        }
      }
      return false;
    },

    getCommerceAddonCollection() {
      let data = null;
      const products = ProfileModel.get('products');

      if (!products) {
        // return empty collection so it will not error when isCommerceAddonActive is called and always return false
        // this should only happen if the session is expired
        return new Backbone.Collection();
      }

      products.forEach((item) => {
        if (!data && item.alias === 'main' && item.product_type.module_name === 'CommerceModule') {
          data = item;
        }
      });
      if (!data) {
        return null;
      }
      return new Backbone.Collection(data.product_addon_types);
    },

    isCommerceAddonActive(productAlias) {
      const productTypeCollection = this.getCommerceAddonCollection();
      return !!productTypeCollection.findWhere({
        alias: productAlias,
        module_name: 'CommerceModule',
      });
    },

    hasAccessByProductAlias(productAlias) {
      switch (productAlias) {
        case this.orderAlias:
          return this.hasOrderAccess();
        case this.quoteAlias:
          return this.hasQuoteAccess();
        case this.contractAlias:
          return this.hasContactAccess();
        case this.invoiceAlias:
          return this.hasInvoiceAccess();
        case this.fileAlias:
          return this.hasFileAccess();
        case this.productAlias:
          return this.hasProductAccess();
        case this.relationAlias:
          return this.hasRelationAccess();
        case this.adminAlias:
          return this.hasAdminAccess();
        case this.managerAlias:
          return this.hasManagerAccess();
        case this.cashierAlias:
          return this.hasCashierAccess();
        case this.orderOnlyAlias:
          return this.hasOrderOnlyAccess();
        case this.orderRequestManagerAlias:
          return this.hasOrderRequestManagerAccess();
        default:
          return false;
      }
    },

    hasOrderAccess() {
      return this.isCommerceAddonActive(this.orderAlias) && this.hasFeatureAccess(this.orderAlias);
    },

    hasQuoteAccess() {
      return this.isCommerceAddonActive(this.orderAlias) && this.hasFeatureAccess(this.quoteAlias);
    },

    hasInvoiceAccess() {
      return this.isCommerceAddonActive(this.orderAlias) && this.hasFeatureAccess(this.invoiceAlias);
    },

    hasContractAccess() {
      return this.isCommerceAddonActive(this.orderAlias) && this.hasFeatureAccess(this.contractAlias);
    },

    hasFileAccess() {
      return this.isCommerceAddonActive(this.fileAlias) && this.hasFeatureAccess(this.fileAlias);
    },

    hasProductAccess() {
      return this.isCommerceAddonActive(this.productAlias) && this.hasFeatureAccess(this.productAlias);
    },

    hasRelationAccess() {
      return this.isCommerceAddonActive(this.relationAlias) && this.hasFeatureAccess(this.relationAlias);
    },

    hasSupportAccess() {
      return this.hasFeatureAccess(this.supportAlias);
    },

    hasAdminAccess(profile) {
      profile = profile || ProfileModel;
      return profile.isGroupTypeMemberOf('CommerceModule', 'Commerce', 'admin')
                || profile.isGroupTypeMemberOf('ShopModule', 'shop', 'admin');
    },

    hasManagerAccess(profile) {
      profile = profile || ProfileModel;
      return profile.isGroupTypeMemberOf('CommerceModule', 'Commerce', 'manager');
    },

    hasCashierAccess(profile) {
      profile = profile || ProfileModel;
      return profile.isGroupTypeMemberOf('CommerceModule', 'Commerce', 'cashier');
    },

    hasOrderOnlyAccess(profile) {
      profile = profile || ProfileModel;
      return profile.isGroupTypeMemberOf('CommerceModule', 'Commerce', 'order_only');
    },

    hasOrderRequestManagerAccess(profile) {
      profile = profile || ProfileModel;
      return profile.isGroupTypeMemberOf('CommerceModule', 'Commerce', 'order_request_manager');
    },

    hasContactAccess(profile) {
      profile = profile || ProfileModel;
      return profile.isGroupTypeMemberOf('ShopModule', 'shop', 'contact');
    },

    hasUserAccess(profile) {
      profile = profile || ProfileModel;
      return profile.isGroupTypeMemberOf('ShopModule', 'shop', 'user');
    },

    getIndexRoute() {
      if (this.hasOrderOnlyAccess()) {
        return 'order';
      } if (this.hasOrderAccess()) {
        return 'dashboard';
      } if (this.hasProductAccess()) {
        return 'products';
      } if (this.hasFileAccess()) {
        return 'files/internal';
      }
      return 'settings';
    },

  });

  return new commerceAuth();
});
