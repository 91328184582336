define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
], (AclRouter, LoggedIn) => AclRouter.extend({

  appRoutes: {
    parcels: 'overview',
  },

  acls: [
    new LoggedIn(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/parcels'], callback);
  },
}));
