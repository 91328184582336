define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/shop/components/commerceAuth',
], (AclRouter) => AclRouter.extend({

  appRoutes: {
    // The route name doesn't matter here. I only have it this way to catch all the routes
    'redirect(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)(/:route)': 'redirect',
  },

  getController(callback) {
    return require(['modules/shop/controllers/redirect'], callback);
  },

}));
