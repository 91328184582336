define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/models/Shop',
  'modules/shop/collections/ShopType',
], (
  $, _, Backbone, ShopModel, ShopTypeCollection,
) => ShopModel.extend({

  isType(wantedModuleName, wantedAlias) {
    return (
      this.isTypeModuleName(wantedModuleName)
                && this.isTypeAlias(wantedAlias)
    );
  },

  isTypeModuleName(wantedModuleName) {
    return this.get('shop_type.module_name') === wantedModuleName;
  },

  isTypeAlias(wantedAlias) {
    return this.get('shop_type.alias') === wantedAlias;
  },

  canEdit() {
    const editableAliases = [
      ShopTypeCollection.ALIAS_POINT_OF_SALE,
      ShopTypeCollection.ALIAS_WEB,
      ShopTypeCollection.ALIAS_WEB_WITH_SYNC,
    ];
    return editableAliases.indexOf(this.get('shop_type.alias')) !== -1;
  },

  canDelete() {
    const deletableAliases = [
      ShopTypeCollection.ALIAS_POINT_OF_SALE,
      ShopTypeCollection.ALIAS_WEB,
      ShopTypeCollection.ALIAS_WEB_WITH_SYNC,
    ];
    return deletableAliases.indexOf(this.get('shop_type.alias')) !== -1;
  },

}));
