define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
], (AclRouter, LoggedIn) => AclRouter.extend({

  appRoutes: {
    'featured-attribute-categories': 'featuredAttributeCategories',
  },

  acls: [
    new LoggedIn(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/featuredAttributeCategories'], callback);
  },
}));
