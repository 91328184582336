define([
  'jquery',
  'underscore',
  'backbone',

  'upx.modules/RelationsModule/collections/DataField',

  'upx.modules/RelationsModule/models/DataField',
  'modules/profile/models/profile',
], (
  $, _, Backbone,
  DataFieldCollection,
  DataFieldModel, ProfileModel,
) => {
  const Collection = Backbone.Collection.extend({

    loadDeferred: false,

    NAME: 'menu_listed_features',

    comparator(m) {
      const date = new Date(m.get('date_created'));
      return -date.getTime();
    },

    isLoaded() {
      return this.loadDeferred && this.loadDeferred.state() === 'resolved';
    },
    isLoading() {
      return this.loadDeferred && this.loadDeferred.state() === 'pending';
    },

    load(force = false) {
      if (this.isLoading() || (this.isLoaded() && !force)) {
        return this.loadDeferred;
      }
      const def = new $.Deferred();

      if (force) {
        this.unload();
      }

      const collection = new DataFieldCollection();
      const parameters = {
        start: 0,
        limit: 1,
        sort: [{
          name: 'date_updated',
          dir: 'asc',
        }],
        filters: [
          {
            name: 'relation_data_id__=',
            val: ProfileModel.getRelationDataProfileId(),
          },
          {
            name: 'name__=',
            val: this.NAME,
          },
        ],
      };
      $.when(
        collection.fetch({ params: parameters }),
      ).then(() => {
        const model = collection.first();
        if (model) {
          try {
            const value = model.get('value');
            const object = JSON.parse(value);
            if (_.isArray(object)) {
              this.add(object);
            }
          } catch (e) {
            // Error is thrown silently. Because if this fails the user can favourite apps again
            // Which will fix the value.
            console.error('Error loading FavouriteApplication', e);
          }
        }
        this.sort();
        def.resolve();
      }, def.reject);

      this.loadDeferred = def.promise();
      return this.loadDeferred;
    },

    unload() {
      this.loadDeferred = false;
      this.reset();
    },

    addFavourite(applicationId) {
      this.add({
        id: applicationId,
        date_created: new Date().toString(),
      });

      this.sort();

      return this._saveData();
    },

    removeFavourite(applicationId) {
      this.remove(applicationId);

      this.sort();

      return this._saveData();
    },

    isFavourite(applicationId) {
      return !!this.get(applicationId);
    },

    _saveData() {
      const model = new DataFieldModel({
        name: this.NAME,
        value: JSON.stringify(this.toJSON()),
        relation_data_id: ProfileModel.getRelationDataProfileId(),
      });

      return model.setDataField();
    },

  });

  return new Collection();
});
