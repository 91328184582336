define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ProductsModule/collections/TaxRate',
  'modules/shop/models/TaxRate',

  'modules/common/components/locale',
  'modules/profile/models/profile',
  'modules/common/collections/countries',
  'modules/shop.common/models/upx/ProductConfiguration',
], (
  $, _, Backbone, UpxCollection, TaxRateModel,
  Locale, ProfileModel, CountriesCollection, ProductConfigurationModel,
) => {
  const Collection = UpxCollection.extend({

    ALIAS_SPECIAL_COMMUNITY_INTRA: 'special_community_intra',
    ALIAS_SPECIAL_COMMUNITY_INTRA_SERVICE: 'special_community_intra_service',
    ALIAS_SPECIAL_NOT_APPLICABLE: 'special_outside_eu',
    ALIAS_SPECIAL_EMBALLAGE_PACKAGING: 'special_emballage_packaging',
    ALIAS_SPECIAL_MARGIN: 'special_margin',

    offline: false,

    loaded: false,

    model: TaxRateModel,

    load(force) {
      const def = new $.Deferred();

      if (!this.loaded || force) {
        this.fetch(this._getParams())
          .then(() => {
            this.each((model) => {
              model.set('title', this.getTaxRateTitleForModel(model));
            });
            this.sort();
            this.loaded = true;
            def.resolve();
          }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    reload() {
      const def = new $.Deferred();

      if (this.loaded) this.unload();

      this.load()
        .then(def.resolve, def.reject);

      return def;
    },

    _getParams() {
      return {
        params: {
          start: 0,
          limit: 0,
        },
        add: true,
        remove: false,
        merge: true,
      };
    },

    unload() {
      this.loaded = false;
      const cache = this.getCacheStorage();
      if (cache) {
        cache.cleanup(); // clean the cache to not load old profile
      }
    },

    getValue(id) {
      const model = this.get(id);
      if (model) {
        return model.get('value');
      }
      return null;
    },

    searchByKey(search, key) {
      search = search.toLowerCase();
      return this.filter((model) =>
        // indexOf(-1) -> not found -> return 0 -> false
        // other are true
        ~model.get(key).toLowerCase().indexOf(search));
    },

    getByCountryIso2(country_iso2) {
      country_iso2 = country_iso2.toUpperCase();
      const items = this.where({ country_iso2 });
      return new UpxCollection(items);
    },

    getByAliasAndCountry(alias, countryIso2) {
      const taxRateModel = this.findWhere({ alias, country_iso2: countryIso2 });
      if (!taxRateModel) {
        throw new Error(`No tax rate found with alias "${alias}" and country_iso2 "${countryIso2}"`);
      }
      return taxRateModel;
    },

    specialCommunityIntraAllowed(customerCountryIso2, customerVatNumber) {
      const companyCountryIso2 = ProfileModel.get('relation_data.contact_address.country_iso2');
      const companyVatNumber = ProfileModel.get('relation_data.business_data.vat_number');
      const europeanUnionIso2s = CountriesCollection.EUROPEAN_UNION_ISO2S;
      // Company var check
      return companyCountryIso2 && companyVatNumber
                    // Customer var check
                    && customerCountryIso2 && customerVatNumber
                    // Countries are different
                    && companyCountryIso2 !== customerCountryIso2
                    // company country in european union
                    && europeanUnionIso2s.indexOf(companyCountryIso2) !== -1
                    // customer country in european union
                    && europeanUnionIso2s.indexOf(customerCountryIso2) !== -1;
    },

    specialOutsideEuAllowed(customerCountryIso2) {
      const companyCountryIso2 = ProfileModel.get('relation_data.contact_address.country_iso2');
      const europeanUnionIso2s = CountriesCollection.EUROPEAN_UNION_ISO2S;
      return companyCountryIso2
          && customerCountryIso2
          // Countries are different
          && companyCountryIso2 !== customerCountryIso2
          // company country in european union
          && europeanUnionIso2s.indexOf(companyCountryIso2) !== -1
          // customer country not in european union
          && europeanUnionIso2s.indexOf(customerCountryIso2) === -1;
    },

    getTaxRateDescription(alias, country) {
      if (country === 'EU') {
        if (alias === this.ALIAS_SPECIAL_COMMUNITY_INTRA) {
          return Locale.translate('intra_community_vat');
        }
        if (alias === this.ALIAS_SPECIAL_COMMUNITY_INTRA_SERVICE) {
          return Locale.translate('intracommunity_service_vat');
        }
        if (alias === this.ALIAS_SPECIAL_NOT_APPLICABLE) {
          return Locale.translate('world_vat_0percent_outside_eu');
        }
      }
      if (country === 'WO') {
        if (alias === 'special_applicable_not_vat') {
          return Locale.translate('not_applicable');
        }
        if (alias === 'special_margin') {
          return Locale.translate('margin');
        }
        if (alias === this.ALIAS_SPECIAL_EMBALLAGE_PACKAGING) {
          return Locale.translate('emballage');
        }
      }
      return '';
    },

    formatPercentage(value) {
      const val = parseFloat(value) * 100;
      return `${val}%`;
    },

    getTaxRateTitleForValue(value) {
      const percentage = parseFloat(value) * 100;
      if (_.isNumber(percentage)) {
        return Locale.translate('vat_{percentage}percent', { percentage });
      }
      console.error('Wrong tax value passed', { value });
      return '';
    },

    getTaxRateTitleForModel(tax) {
      const description = this.getTaxRateDescription(tax.get('alias'), tax.get('country_iso2'));
      if (description !== '') {
        const percent = this.formatPercentage(tax.get('value'));
        if (description.indexOf(percent) === -1) {
          return `${percent} ${description}`;
        }
        return description;
      }
      const taxRateTitleForValue = this.getTaxRateTitleForValue(tax.get('value'));
      if (ProductConfigurationModel.get('tax_rate.country_iso2') !== tax.get('country_iso2')) {
        const name = CountriesCollection.getTranslatedName(tax.get('country_iso2'));
        return `${taxRateTitleForValue} (${name})`;
      }

      return taxRateTitleForValue;
    },

    getFilteredByEuCountry(country_iso2) {
      const models = this.filter(
        (model) => {
          const modelCountry = model.get('country_iso2');
          return modelCountry === country_iso2
                || modelCountry === 'WO'
                || modelCountry === 'EU';
        },
      );

      return new Collection(models);
    },

    getFilteredByConfiguration() {
      const country_iso2 = ProfileModel.get('relation_data.contact_address.country_iso2');
      return this.getFilteredByEuCountry(country_iso2);
    },

    getTaxRateTitleForOrder(orderTax) {
      if (orderTax.tax_rate_id) {
        const tax = this.get(orderTax.tax_rate_id);
        return this.getTaxRateTitleForModel(tax);
      }
      if (orderTax.tax) {
        return this.getTaxRateTitleForValue(orderTax.tax);
      }
      console.error('Failed to get order tax title', orderTax);
      return orderTax.title;
    },

    getFilteredByConfigurationForSelect2() {
      return this.getFilteredByConfiguration().map((model) => {
        const data = model.toJSON();
        data.label = model.getLabel();
        return data;
      });
    },
  });

  const TaxRates = new Collection();
  TaxRates.comparator = 'title';
  return TaxRates;
});
