define([
  'application',
  'jquery',
  'modules/common/subscribers/subscriber',
  'modules/profile/events/profile/load',
  'modules/profile/events/profile/afterLoad',
  'modules/upx/collections/users',
  'modules/common/components/locale',
  'modules/shop.common/components/localeSetter',

  'modules/blog/models/configuration',
  'modules/shop/models/shopConfiguration',
  'modules/shop/models/commerceConfiguration',

  'modules/shop/models/billingConfiguration',

  'modules/shop/collections/upx/Shops',

  'modules/shop/collections/TranslatedAttribute',
  'modules/shop/collections/TranslatedAttributeSet',

  'modules/shop/collections/ShopConfigurationAttachedGroup',
  'modules/shop/collections/TaxRate',
  'modules/shop/collections/Questionnaire',

  'modules/shop/collections/PriceListType',
  'modules/shop/collections/ProductPriceType',
  'modules/shop.common/models/upx/ProductConfiguration',

  'modules/shop/collections/TaskType',
  'modules/shop/collections/TaskCategory',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.common/collections/upx/FeaturedAttribute',

  'modules/shop.common/components/commerceAuth',
  'modules/common/components/historyBreadcrumb',
  'modules/shop/components/onboardflow',
  'modules/shop/models/upx/DefaultShopAddress',

  'modules/shop/components/googleAnalytics',
  'modules/shop/components/pendo.js',
  'modules/shop/components/feature',
],
(
  App, $, Subscriber, ProfileLoadEvent, ProfileAfterLoadEvent, UserCollection, Locale, LocaleSetter,
  BlogConfigurationModel, ShopConfigurationModel, CommerceConfigurationModel,
  BillingConfigurationModel,
  ShopsCollection,
  TranslatedAttributeCollection, TranslatedAttributeSetCollection,
  ShopConfigurationAttachedGroup, TaxRateCollection, QuestionnaireCollection,
  PriceListTypesCollection, ProductPriceTypeCollection, ProductConfiguration,
  TaskTypeCollection, TaskCategoryCollection, DeviceConfig, FeaturedAttributeCollection,
  CommerceAuth, HistoryBreadcrumb, OnboardFlow, DefaultShopAddressModel,
  GoogleAnalytics, Pendo, Feature,
) => {
  const onLoggedIn = function (event) {
    const self = this;
    const subDef = $.Deferred();
    const user = UserCollection.findWhere({
      active: true,
    });

    event.subDef = subDef;

    if (user !== undefined && !CommerceAuth.hasCashierAccess()) {
      LocaleSetter.setLocaleFromProfile(event.options.profile);

      // Initial load
      $.when(
        BlogConfigurationModel.load(),
        ShopConfigurationModel.load(),
        CommerceConfigurationModel.load(),
        BillingConfigurationModel.load(),
        ProductConfiguration.load(),
        QuestionnaireCollection.load(),
        FeaturedAttributeCollection.load(),
      ).then(
        () => {
          // Secondary load
          $.when(
            ShopsCollection.load(),
            TaxRateCollection.load(),
            TranslatedAttributeCollection.load(),
            TranslatedAttributeSetCollection.load(),
            ShopConfigurationAttachedGroup.load(),
            PriceListTypesCollection.load(),
            ProductPriceTypeCollection.load(),
            DefaultShopAddressModel.load(),
            Feature.load(),
          ).then(
            subDef.resolve,
            subDef.reject,
          );
        },
        subDef.reject,
      );
    } else {
      subDef.reject();
    }

    $.when(subDef).then(
      self.options.preloadDef.resolve,
      self.options.preloadDef.reject,
    );
  };

  function afterLogon() {
    OnboardFlow.load();

    // Check default index
    const indexRoute = CommerceAuth.getIndexRoute();
    HistoryBreadcrumb.setIndex(`#${indexRoute}`);

    // Check if the setup needs to be done
    if (!QuestionnaireCollection.isSetupCompleted()) {
      HistoryBreadcrumb.setIndex('#setup');
    }

    // Load functions that are not initially needed
    TaskTypeCollection.load();
    TaskCategoryCollection.load();

    GoogleAnalytics.setUser();
    Pendo.setUser();
  }

  return Subscriber.extend({
    events: [{
      event: ProfileLoadEvent,
      action: onLoggedIn,
    }, {
      event: ProfileAfterLoadEvent,
      action: afterLogon,
    }],
  });
});
