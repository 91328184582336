define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (
  AclRouter, LoggedIn, CommerceAuthAcl, NotAcl,
) => AclRouter.extend({

  appRoutes: {
    settings: 'overview',
    'setting/default(/:tabName)': 'defaultSettings',
    'setting/technical(/:tabName)': 'technicalSettings',
    'setting/advanced(/:tabName)': 'advancedSettings',
    'setting/:name': 'details',
  },

  acls: [
    new LoggedIn(),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/settings'], callback);
  },
}));
