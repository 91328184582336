define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, NotAcl) => AclRouter.extend({

  appRoutes: {
    'sales-channels': 'overview',
    'sales-channels/create-retail-store': 'createRetailStore',
    'sales-channels/create-food-store': 'createFoodStore',
    'sales-channels/details/:shop_id(/:swapTo)': 'details',
    'sales-channels/navigations/:shop_id': 'navigations',
    'sales-channels/reports(/:shop_id)': 'reports',
    'sales-channels/dashboard(/:shop_id)': 'dashboard',
  },

  acls: [
    new LoggedIn(),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/salesChannels'], callback);
  },
}));
