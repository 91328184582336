define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/commerceAuth',
  'modules/shop/components/commerceAuth',
  'modules/common/acls/notAcl',
], (AclRouter, LoggedIn, CommerceAuthAcl, CommerceAuth, NotAcl) => AclRouter.extend({

  appRoutes: {
    'company-info(/:tabName)': 'companyInfo',
  },

  acls: [
    new LoggedIn(),
    new CommerceAuthAcl({
      alias: [
        CommerceAuthAcl.orderRequestManagerAlias,
        CommerceAuthAcl.managerAlias,
        CommerceAuthAcl.adminAlias,
      ],
    }),
    new NotAcl(new CommerceAuthAcl({ alias: CommerceAuthAcl.orderOnlyAlias })),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/companyInformation'], callback);
  },

}));
